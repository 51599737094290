import { type ReactNode } from "react";
import dynamic from "next/dynamic";

import {
  OverlayInlineEdit,
  HightLight,
} from "@fwa/src/components/Highlight/styles";

const FocusLock = dynamic(() => import("react-focus-lock"));

type Props = {
  isHighlighted: boolean;
  children?: ReactNode;
  fullWidth?: boolean;
};

// Component used for in-line-edit / edit-in-place, adds and overlay over everything else
// and creates a focus lock around the highlighted children
export const Highlight = ({
  isHighlighted = false,
  fullWidth = false,
  children,
}: Props) =>
  isHighlighted ? (
    <>
      <HightLight $fullWidth={fullWidth}>
        <FocusLock returnFocus>{children}</FocusLock>
      </HightLight>
      <OverlayInlineEdit />
    </>
  ) : (
    <>{children}</>
  );

export default Highlight;

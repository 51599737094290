import {
  type OptimizelyDecideOption,
  type OptimizelyDecision,
  type OptimizelyUserContext,
} from "@optimizely/optimizely-sdk";

import { useOptimizelyContext } from "@fwa/src/contexts/OptimizelyContext";
import { sendGTMTrackingEvent } from "@fwa/src/services/gtm";

type OptimizelyDecisionEmpty = {
  variationKey: string | null;
  // The boolean value indicating if the flag is enabled or not
  enabled: boolean;
  // The collection of variables associated with the decision
  variables: Record<string, unknown>;
  // The rule key of the decision
  ruleKey: string | null;
  // The flag key for which the decision has been made for
  flagKey: string;
  // A copy of the user context for which the decision has been made for
  userContext: OptimizelyUserContext | null;
  // An array of error/info messages describing why the decision has been made.
  reasons: string[];
};

const createEmptyDecsion = (flagKey: string) => ({
  variationKey: null,
  enabled: false,
  ruleKey: null,
  flagKey,
  variables: {},
  reasons: [],
  userContext: null,
});

// Should be able to run test like the following:
// conditionally run test in the front end but passing the correct ID or empty string
//
// const HomePage = () => {
//   const { decision } = useOptimizelyDecision(
//     page?.target ? "donation_form_totaliser" : ""
//   );
//
//   return (
//     <>
//       {decision.enabled ? (
//         <Text>{`Feature enabled!`}</Text>
//       ) : (
//         <Text>{`Feature NOT enabled!`}</Text>
//       )}
//     </>)

/**
 * A React Hook that retrieves the flag decision, optionally
 * auto updating those values based on underlying user or datafile changes.
 *
 * Note: The react client can become ready AFTER the timeout period.
 *       ClientReady and DidTimeout provide signals to handle this scenario.
 */
export const useOptimizelyDecision = (
  flagKey: string,
  options: OptimizelyDecideOption[] = [],
): {
  error: Error | null;
  decision: OptimizelyDecision | OptimizelyDecisionEmpty;
} => {
  const { optimizelyContext } = useOptimizelyContext();
  const { optimizelyClient, optimizelyUserContext } = optimizelyContext;

  if (!optimizelyClient) {
    return {
      error: new Error(
        `Unable to use decision ${flagKey}. Optimizely client not set up`,
      ),
      decision: createEmptyDecsion(flagKey),
    };
  }

  if (!optimizelyUserContext) {
    return {
      error: new Error(
        `Unable to use decision ${flagKey}. Optimizely user context not set up`,
      ),
      decision: createEmptyDecsion(flagKey),
    };
  }

  const decision: OptimizelyDecision = optimizelyUserContext.decide(
    flagKey,
    options,
  );

  if (!decision) {
    return {
      error: new Error(`Unable to use decision ${flagKey}. Decision failed`),
      decision: createEmptyDecsion(flagKey),
    };
  }

  sendGTMTrackingEvent({
    event: "Optimizely",
    flag: decision.flagKey,
    enabled: decision.enabled,
    variation: decision.variationKey || "",
  });
  return {
    error: null,
    decision,
  };
};

export default useOptimizelyDecision;

import React from "react";
import dynamic from "next/dynamic";

import { type FitbitType, type FundraisingPageType } from "@fwa/src/types";

const FitbitTracker = dynamic(
  () => import("@fwa/src/components/FitbitTracker"),
);
const FitbitTrackerForm = dynamic(
  () => import("@fwa/src/components/FitbitTrackerForm"),
);

export const FitbitSection = ({
  fitbit,
  mutatePage,
  canEdit,
}: {
  canEdit: boolean;
  fitbit: FitbitType;
  mutatePage: (
    data: Partial<FundraisingPageType>,
  ) => Promise<undefined | void | FundraisingPageType>;
}) => {
  if (!canEdit) {
    return <FitbitTracker canEdit={canEdit} fitbit={fitbit} />;
  } else {
    return <FitbitTrackerForm fitbit={fitbit} mutatePage={mutatePage} />;
  }
};

export default FitbitSection;

import React, { useCallback, useEffect, useRef, useState } from "react";
import { Badge, Box, Text } from "@cruk/cruk-react-components";
import { type PageType } from "@fwa/src/types";
import { CollapseCentered } from "@fwa/src/components/CollapseCentered";
import { numberWithCommas } from "@fwa/src/utils/formatUtils";
import {
  CarouselCard,
  CarouselScrollArea,
  RoundedImageWrapper,
  BadgeWrapper,
  CarouselFade,
  CarouselOuter,
} from "@fwa/src/components/TotaliserImpactCarousel/styles";
import { RowCenter } from "@fwa/src/components/styles";

const impactStatement25 = "/assets/images/img/impact_statement_25.jpg";
const impactStatement50 = "/assets/images/img/impact_statement_50.png";
const impactStatement100 = "/assets/images/img/impact_statement_100.jpg";
const impactStatement150 = "/assets/images/img/impact_statement_250.jpg";
const impactStatement200 = "/assets/images/img/impact_statement_200.png";
const impactStatement300 = "/assets/images/img/impact_statement_300.png";
const impactStatement500 = "/assets/images/img/impact_statement_500.jpg";
const impactStatement1000 = "/assets/images/img/impact_statement_1000.jpg";
const impactStatement2500 = "/assets/images/img/impact_statement_2500.jpg";
const impactStatement5000 = "/assets/images/img/impact_statement_5000.jpg";

const impactStatements = [
  {
    value: 25,
    img: impactStatement25,
    title: "Kitting out our labs",
    description:
      "£25 could either buy 500 plastic test tubes, 5 boxes of gloves, 5 beakers, or a Bunsen burner, which are the understated tools of many experiments.",
  },
  {
    value: 50,
    img: impactStatement50,
    title: "Unravelling mysteries",
    description:
      "£50 can pay for chemicals called restriction enzymes, which cut long strands of DNA, allowing scientists to find out more about the genes that cause cancer.",
  },
  {
    value: 100,
    img: impactStatement100,
    title: "Spotting cancer earlier",
    description:
      "Early diagnosis is essential to getting the right treatment to people with cancer. £100 could pay for a cancer biopsy, where a tiny amount of a lump is removed and sent to be studied in a lab.",
  },
  {
    value: 150,
    img: impactStatement150,
    title: "Making diagnosis kinder",
    description:
      "£150 could fund a team of researchers in Manchester for half a day. They're trying to diagnose and monitor lung cancer in a less invasive way.",
  },
  {
    value: 200,
    img: impactStatement200,
    title: "Understanding errors in DNA",
    description:
      "£200 can buy DNA nucleotides, something scientists use to make copies of genes, giving us clues about how things go wrong in cancer.",
  },
  {
    value: 300,
    img: impactStatement300,
    title: "Finding cancer's weakness",
    description:
      "£300 could buy a device used to spin blood samples so different types of cells separate. Our scientists can then study the different types of cancerous cells to find out more about them.",
  },
  {
    value: 500,
    img: impactStatement500,
    title: "Providing cancer support",
    description:
      "£500 could run our online moderated forum, Cancer Chat, for a day. It's a safe space open to everyone to discuss any aspect of the disease with others in the cancer community.",
  },
  {
    value: 1000,
    img: impactStatement1000,
    title: "Hunting innovations",
    description:
      "To help test potentially life-saving treatments and find the most promising new cancer drugs, £1,000 could buy imaging software that our scientists use to film cells in real time.",
  },
  {
    value: 2500,
    img: impactStatement2500,
    title: "Delivering treatments",
    description:
      "The PETReA trial aims to help doctors decide how best to treat lymphoma, a cancer of the white blood cells. £2,500 could fund this trial for five days.",
  },
  {
    value: 5000,
    img: impactStatement5000,
    title: "Keeping cancer in remission",
    description:
      "£5,000 could fund the Add-Aspirin trial for five days. This clinical trial is investigating whether aspirin could stop some types of cancer coming back after treatment.",
  },
];

export type CarouselProps = {
  page: PageType;
};

export const TotaliserImpactCarousel = ({ page }: CarouselProps) => {
  const scrollRef = useRef<HTMLUListElement>(null);
  const targetOrTotalValue = Math.max(
    page.donationsTotalAmount || 0,
    page.target || 0,
  );

  const filterStatements = (statements: typeof impactStatements) => {
    const filteredByValue = statements.filter(
      (statement) => statement.value <= targetOrTotalValue,
    );

    if (statements[filteredByValue.length])
      return [...filteredByValue, statements[filteredByValue.length]];

    return filteredByValue;
  };
  const filteredImpactStatements =
    !page.target && page.donationsTotalAmount === 0
      ? impactStatements
      : filterStatements(impactStatements);

  const [allCollapseState, setAllCollapseState] = useState(
    filteredImpactStatements.map(() => false),
  );

  const scrollToPosition = useCallback(() => {
    const getCurrentItem = (total: number) => {
      const index = filteredImpactStatements.findIndex(
        (item) => item.value > total,
      );
      if (index === -1) return filteredImpactStatements.length - 1;
      return index - 1;
    };

    const scrollElement = scrollRef.current;
    if (!scrollElement || !page?.donationsTotalAmount) return;
    const currentItem = getCurrentItem(page?.donationsTotalAmount);
    if (currentItem < 0) return;
    const element = scrollElement.children[currentItem] as HTMLDivElement;
    if (!element) return;
    const scrollX = element.offsetLeft - scrollElement.clientWidth / 2;
    scrollElement.scrollTo({ top: 0, left: scrollX, behavior: "instant" });
  }, [filteredImpactStatements, page?.donationsTotalAmount]);

  useEffect(() => {
    if (page?.donationsTotalAmount) {
      scrollToPosition();
    }
  }, [page?.donationsTotalAmount, scrollToPosition]);

  return (
    <Box marginBottom="xxl">
      <CarouselOuter id="totaliser_impact_milestones">
        <CarouselScrollArea
          ref={scrollRef}
          aria-live="polite"
          aria-label="Fundraising impact"
          aria-roledescription="Carousel"
          tabIndex={0}
          $smoothScrolling
        >
          {filteredImpactStatements.map((milestone, index) => (
            <CarouselCard
              key={milestone.value}
              $isFirst={index === 0}
              $isLast={index === filteredImpactStatements.length - 1}
            >
              <CollapseCentered
                title={milestone.title}
                ariaLabel={`£${milestone.value}`}
                widenContentBy="0"
                allCollapseState={allCollapseState}
                setAllCollapseState={setAllCollapseState}
                collapseIndex={index}
                customHeader={
                  <>
                    <RoundedImageWrapper
                      $isCurrent={page.donationsTotalAmount >= milestone.value}
                    >
                      <img src={milestone.img} alt="" />
                    </RoundedImageWrapper>
                    <RowCenter>
                      <BadgeWrapper>
                        <Badge
                          backgroundColor="totaliserBubbleColor"
                          textColor="totaliserBubbleTotalColor"
                          aria-hidden="true"
                        >
                          {`£${numberWithCommas(milestone.value)}`}
                        </Badge>
                      </BadgeWrapper>
                    </RowCenter>
                  </>
                }
              >
                <Text>{milestone.description}</Text>
              </CollapseCentered>
            </CarouselCard>
          ))}
        </CarouselScrollArea>
        <CarouselFade />
      </CarouselOuter>
    </Box>
  );
};
export default TotaliserImpactCarousel;

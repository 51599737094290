import NextLink from "next/link";
import { useEffect, useState } from "react";
import styled from "styled-components";
import * as QRCode from "qrcode";

import {
  faDownload,
  faPrint,
  faQrcode,
} from "@fortawesome/free-solid-svg-icons";
import {
  Heading,
  Text,
  IconFa,
  Modal,
  Box,
  Button,
  type ThemeType,
  Collapse,
} from "@cruk/cruk-react-components";

import { ColumnAlignCenter } from "@fwa/src/components/styles";

import { useOptimizelyContext } from "@fwa/src/contexts/OptimizelyContext";
import { useTrackingContext } from "@fwa/src/contexts/TrackingContext";

import { isBrowser } from "@fwa/src/utils/browserUtils";
import { useRouter } from "next/router";
import { useModalContext } from "@fwa/src/contexts/ModalContext";

const QR_CODE_WIDTH = 240;
const QR_CODE_DOWNLOAD_WIDTH = 1024;

const QrCodeWrapper = styled.div<{ theme: ThemeType }>`
  max-width: ${QR_CODE_WIDTH}px;
  margin-bottom: ${({ theme }) => theme.spacing.s};
  img {
    width: 100%;
    height: auto;
  }
`;

const RowReverse = styled.div`
  display: flex;
  flex-direction: row-reverse;
`;

export const QrModal = () => {
  const router = useRouter();
  const url = router.asPath;
  const { trackError } = useTrackingContext();
  const [qrCodeImageDataUrl, setQrCodeImageDataUrl] = useState<string>("");
  const [qrCodeImageDownloadDataUrl, setQrCodeImageDownloadDataUrl] =
    useState<string>("");
  const { trackEvent } = useOptimizelyContext();
  const [, setModalState] = useModalContext();

  const handleClose = () => {
    setModalState(false);
  };

  useEffect(() => {
    if (!isBrowser) return;
    const linkUrl = `${window.location.href.split("?")[0]}?from=qr`;

    // display QR code
    QRCode.toDataURL(linkUrl, {
      type: "image/png",
      width: QR_CODE_WIDTH,
      margin: 0,
    })
      .then((imageUrl: string) => {
        setQrCodeImageDataUrl(imageUrl);
        return;
      })
      .catch((err: Error) => {
        trackError(err, { component: "QrCodeSettings" });
      });

    // download QR code
    QRCode.toDataURL(linkUrl, {
      type: "image/png",
      width: QR_CODE_DOWNLOAD_WIDTH,
      margin: 0,
    })
      .then((imageUrl: string) => {
        setQrCodeImageDownloadDataUrl(imageUrl);
        return;
      })
      .catch((err: Error) => {
        trackError(err, { component: "QrCodeSettings" });
      });
  }, [trackError]);

  return (
    <Modal
      modalName="qr code modal"
      closeFunction={handleClose}
      showCloseButton
    >
      <Box>
        <Heading h2 textSize="xl" marginTop="l" marginBottom="l">
          <Box as="span" marginRight="xxs">
            <IconFa faIcon={faQrcode} />
          </Box>
          Scan or print QR code
        </Heading>
        <Text>
          Scanning this QR code provides an easy way to bring people to this
          Giving Page when out and about.
        </Text>
        <Text>
          Show supporters the QR code below, or you can print posters with the
          QR code to use later.
        </Text>

        <Collapse
          headerTitleText="How can I scan this QR code?"
          id="qr-code-explanation"
        >
          <Text marginBottom="m">
            <ol>
              <li>
                Ask supporters to open their phone camera and point it at the QR
                code.
              </li>
              <li>
                Once the phone camera detects the QR code, a button or link
                should appear.
              </li>
              <li>
                Clicking the button or link will take them to this Giving Page
                when connected to the internet.
              </li>
            </ol>
          </Text>
        </Collapse>

        <Collapse
          headerTitleText="How can I print or download this QR code?"
          id="qr-download-explanation"
        >
          <Text marginBottom="m">
            {`Select "Print poster" to open a new tab with a printable poster
            which includes the QR code.`}
          </Text>
          <Text marginBottom="m">
            {`Select "Download image" to download the QR code as an image file.
            You can then resize and print it to use in your own fundraising materials such
            as collection buckets and t-shirts.`}
          </Text>
        </Collapse>

        <Box marginTop="l" marginBottom="l">
          <ColumnAlignCenter>
            <QrCodeWrapper>
              <img src={qrCodeImageDataUrl} alt="QR code" />
            </QrCodeWrapper>
            {url ? (
              <Box>
                <NextLink
                  href={url.replace("page", "page/print")}
                  target="_blank"
                  onClick={() => {
                    trackEvent({ eventKey: "of_goto_qr_print_path" });
                  }}
                  data-cta-type="qr-code-print-link"
                >
                  <Button appearance="secondary" as="span">
                    <Box as="span" marginRight="xxs">
                      <IconFa faIcon={faPrint} />
                    </Box>
                    Print Poster
                  </Button>
                </NextLink>
              </Box>
            ) : null}
            <Box marginBottom="s">
              <a
                href={qrCodeImageDownloadDataUrl}
                data-cta-type="qr-code-download"
                onClick={() => {
                  trackEvent({ eventKey: "of_download_qr" });
                }}
                download="qr-code.png"
                title="Download QR code"
                style={{ textDecoration: "none" }}
              >
                <Button appearance="primary" as="span">
                  <Box as="span" marginRight="xxs">
                    <IconFa faIcon={faDownload} />
                  </Box>
                  Download image
                </Button>
              </a>
            </Box>
          </ColumnAlignCenter>
        </Box>
        <RowReverse>
          <Button
            appearance="secondary"
            onClick={() => {
              handleClose();
            }}
          >
            Dismiss
          </Button>
        </RowReverse>
      </Box>
    </Modal>
  );
};

export default QrModal;

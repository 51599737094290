import { useState, type ChangeEvent } from "react";
import {
  Text,
  Checkbox,
  Box,
  DateField,
  TextField,
  ErrorText,
} from "@cruk/cruk-react-components";
import { parseISO, addHours, isValid } from "date-fns";

import { isValidDate } from "@fwa/src/utils/timeUtils";
import { prefixSingleWithZero } from "@fwa/src/utils/formatUtils";
import { useTrackingContext } from "@fwa/src/contexts/TrackingContext";

import { InMemory } from "@fwa/src/components/InMemory";
import { OnlyYou } from "@fwa/src/components/OnlyYou";

import { DashedBorder, ResponsiveRow } from "@fwa/src/components/styles";
import {
  ACTIVITY_IN_MEMORY_MAX_LENGTH,
  fundraisingPageActivityInMemoryValidation,
  type InMemoryFormValues,
} from "@fwa/src/validation/page";

import {
  type FundraisingPageType,
  type InMemoryDateType,
  type PageType,
} from "@fwa/src/types";
import Editable from "@fwa/src/components/Editable";
import { calcLength } from "@fwa/src/utils/formUtils";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

type Props = {
  page: FundraisingPageType;
  name?: string;
  handleEditData: (
    data: Partial<PageType>,
    refresh?: boolean,
  ) => Promise<void | PageType>;
  canEdit: boolean;
};

const placeHolder = (
  <Box>
    <DashedBorder>
      <OnlyYou />
      <Text>Are you raising money in memory of someone?</Text>
    </DashedBorder>
  </Box>
);

const DateFieldWrapper = styled(Box)`
  min-width: 16rem;
`;

const midDayDateFromISODateString = (isoDateString: string): Date =>
  addHours(parseISO(isoDateString), 12);

const getDatesFromState = (newState: InMemoryDateType) => {
  const birthDatePartial = `${newState.birthYear || ""}${
    newState.birthMonth || ""
  }${newState.birthDay || ""}`;
  const deathDatePartial = `${newState.deathYear || ""}${
    newState.deathMonth || ""
  }${newState.deathDay || ""}`;
  const birthDate = parseISO(
    `${newState.birthYear || ""}-${prefixSingleWithZero(
      newState.birthMonth || "",
    )}-${prefixSingleWithZero(newState.birthDay || "")}`,
  );
  birthDate.setHours(12);
  const deathDate = parseISO(
    `${newState.deathYear || ""}-${prefixSingleWithZero(
      newState.deathMonth || "",
    )}-${prefixSingleWithZero(newState.deathDay || "")}`,
  );
  deathDate.setHours(12);
  const birthValid = isValidDate(birthDate);
  const deathValid = isValidDate(deathDate);
  return {
    birthDate,
    deathDate,
    birthValid,
    deathValid,
    birthDatePartial,
    deathDatePartial,
  };
};

// We need to submit dates as yyyy-mm-dd
// so we need to take the days the months and the years figure out if they make valid dates
// and then convert them to the ISO string format
const inMemoryStateDateToIsoDates = (newState: InMemoryDateType) => {
  const { birthDate, deathDate, birthValid, deathValid } =
    getDatesFromState(newState);
  return {
    birthISOString:
      birthDate && birthValid ? birthDate.toISOString().split("T")[0] : null,
    deathISOString:
      deathDate && deathValid ? deathDate.toISOString().split("T")[0] : null,
  };
};

export const EditableInMemoryForm = ({
  page,
  handleEditData,
  canEdit,
}: Props) => {
  const { trackError } = useTrackingContext();

  const { dateOfBirthDeceased, dateOfDeathDeceased, activityInMemory } = page;
  const [showDate, setShowDate] = useState<boolean>(
    !!dateOfBirthDeceased || !!dateOfDeathDeceased || false,
  );
  const [isLoading, setIsLoading] = useState(false);

  const [nameCharCount, setNameCharCount] = useState(
    calcLength(activityInMemory || ""),
  );

  const [dateState, setDateState] = useState<InMemoryDateType>({
    birthDay: dateOfBirthDeceased
      ? midDayDateFromISODateString(dateOfBirthDeceased).getDate().toString()
      : null,
    birthMonth: dateOfBirthDeceased
      ? (
          midDayDateFromISODateString(dateOfBirthDeceased).getMonth() + 1
        ).toString()
      : null,
    birthYear: dateOfBirthDeceased
      ? midDayDateFromISODateString(dateOfBirthDeceased)
          .getFullYear()
          .toString()
      : null,
    deathDay: dateOfDeathDeceased
      ? midDayDateFromISODateString(dateOfDeathDeceased).getDate().toString()
      : null,
    deathMonth: dateOfDeathDeceased
      ? (
          midDayDateFromISODateString(dateOfDeathDeceased).getMonth() + 1
        ).toString()
      : null,
    deathYear: dateOfDeathDeceased
      ? midDayDateFromISODateString(dateOfDeathDeceased)
          .getFullYear()
          .toString()
      : null,
  });

  const defaultValues = {
    activityInMemory: page.activityInMemory || "",
    dateOfBirthDeceased: isValid(parseISO(dateOfBirthDeceased || ""))
      ? parseISO(dateOfBirthDeceased || "")
      : undefined,
    dateOfDeathDeceased: isValid(parseISO(dateOfDeathDeceased || ""))
      ? parseISO(dateOfDeathDeceased || "")
      : undefined,
  };

  const formProps = useForm<InMemoryFormValues>({
    mode: "onBlur",
    reValidateMode: "onBlur",
    defaultValues,
    criteriaMode: "firstError",
    shouldFocusError: true,
    resolver: zodResolver(fundraisingPageActivityInMemoryValidation),
    resetOptions: {
      keepDirtyValues: true, // user-interacted input will be retained
      keepErrors: false, // input errors will be retained with value update
    },
  });

  const {
    clearErrors,
    register,
    trigger,
    setValue,
    setError,
    formState: { errors },
    getValues,
  } = formProps;

  const doSubmit = async (): Promise<PageType | void> => {
    clearErrors();
    if (showDate) {
      const isFormValid = await trigger();
      if (!isFormValid) {
        setIsLoading(false);
        return;
      }
    } else {
      const isNameValid = await trigger("activityInMemory");
      if (!isNameValid) {
        setIsLoading(false);
        return;
      }
    }
    // once validation passes
    setIsLoading(true);
    const { birthISOString, deathISOString } =
      inMemoryStateDateToIsoDates(dateState);
    // convert dates to ISOString for FWS
    const dateOfBirthDeceased = showDate ? birthISOString : null;
    const dateOfDeathDeceased = showDate ? deathISOString : null;

    return handleEditData({
      activityInMemory: getValues("activityInMemory"),
      dateOfBirthDeceased,
      dateOfDeathDeceased,
    })
      .then((pageData) => {
        setIsLoading(false);
        return pageData;
      })
      .catch((error) => {
        setError("root", { type: "submit", message: "Unable to submit" });
        trackError(error as Error, { component: "EditableInMemoryForm" });
        throw error;
      });
  };

  // hides/shows the dates
  const handleShowDatesChange = (e: ChangeEvent<HTMLInputElement>) => {
    setShowDate(e.target.checked);
  };

  // we don't want to show the error message
  // when people are still hopping from date to month to year
  const handleBirthDateFocus = () => {
    clearErrors("dateOfBirthDeceased");
  };
  const handleDeathDateFocus = () => {
    clearErrors("dateOfDeathDeceased");
  };
  const handleNameBlur = () => {
    trigger("activityInMemory", { shouldFocus: true }).catch(() => {
      // no op don't let revalidation err break form
    });
  };
  const handleBirthDateBlur = () => {
    if (
      dateState.birthDay?.length &&
      dateState.birthMonth?.length &&
      dateState.birthYear?.length
    ) {
      trigger("dateOfBirthDeceased").catch(() => {
        // no op don't let revalidation err break form
      });
    }
  };
  const handleDeathDateBlur = () => {
    if (
      dateState.deathDay?.length &&
      dateState.deathMonth?.length &&
      dateState.deathYear?.length
    ) {
      trigger("dateOfDeathDeceased").catch(() => {
        // no op don't let revalidation err break form
      });
    }
  };

  // updates date state when there is a change in any date component

  const handleDateChanged = (e: ChangeEvent<HTMLInputElement>) => {
    const input = e.target;
    const newState = { ...dateState, [`${input.name}`]: input.value };

    // Controlled val of DateField
    setDateState(newState);

    // these are used for form validation
    setValue(
      "dateOfBirthDeceasedPartial",
      `${newState.birthDay || ""}${newState.birthMonth || ""}${
        newState.birthYear || ""
      }`,
    );
    setValue(
      "dateOfDeathDeceasedPartial",
      `${newState.deathDay || ""}${newState.deathMonth || ""}${
        newState.deathYear || ""
      }`,
    );

    const { birthDate, birthValid, deathDate, deathValid } =
      getDatesFromState(newState);
    setValue("dateOfBirthDeceased", birthValid ? birthDate : undefined);
    setValue("dateOfDeathDeceased", deathValid ? deathDate : undefined);
  };

  const handleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setNameCharCount(calcLength(e.target.value));
    setValue("activityInMemory", e.target.value);
  };

  return canEdit ? (
    <div data-component="page-in-memory-form">
      <Editable
        fieldName="inMemory"
        editNode={
          <form noValidate data-component="edit-in-memory-form">
            <Text>In memory of</Text>
            <>
              <TextField
                {...register("activityInMemory")}
                aria-label="in memory name"
                label=""
                onChange={handleNameChange}
                onBlur={handleNameBlur}
                errorMessage={errors.activityInMemory?.message}
                maxLength={ACTIVITY_IN_MEMORY_MAX_LENGTH}
              />
              <Text textAlign="right" marginTop="xxs">{`${
                ACTIVITY_IN_MEMORY_MAX_LENGTH - nameCharCount
              } characters remaining`}</Text>
            </>
            <Box marginBottom="s">
              <Checkbox onChange={handleShowDatesChange} checked={showDate}>
                Add memorial dates
              </Checkbox>
            </Box>
            {showDate && (
              <Box>
                <ResponsiveRow>
                  <DateFieldWrapper>
                    <DateField
                      dayName="birthDay"
                      monthName="birthMonth"
                      yearName="birthYear"
                      day={dateState.birthDay ?? ""}
                      month={dateState.birthMonth ?? ""}
                      year={dateState.birthYear ?? ""}
                      label="When were they born?"
                      onChange={handleDateChanged}
                      onBlur={handleBirthDateBlur}
                      onFocus={handleBirthDateFocus}
                      errorMessage={errors.dateOfBirthDeceased?.message}
                    />
                  </DateFieldWrapper>

                  <DateFieldWrapper>
                    <DateField
                      dayName="deathDay"
                      monthName="deathMonth"
                      yearName="deathYear"
                      day={dateState.deathDay ?? ""}
                      month={dateState.deathMonth ?? ""}
                      year={dateState.deathYear ?? ""}
                      label="When did they pass away?"
                      onChange={handleDateChanged}
                      onBlur={handleDeathDateBlur}
                      onFocus={handleDeathDateFocus}
                      errorMessage={errors.dateOfDeathDeceased?.message}
                    />
                  </DateFieldWrapper>
                </ResponsiveRow>
              </Box>
            )}
            {errors?.root?.message ? (
              <ErrorText>{errors?.root?.message}</ErrorText>
            ) : null}
          </form>
        }
        viewNode={
          !activityInMemory?.length && placeHolder ? (
            <>{placeHolder}</>
          ) : (
            <InMemory
              name={getValues("activityInMemory") || ""}
              birthDateString={dateOfBirthDeceased || undefined}
              deathDateString={dateOfDeathDeceased || undefined}
            />
          )
        }
        handleSubmit={doSubmit}
        isLoading={isLoading}
        tooltip="Edit in memory details"
      />
    </div>
  ) : page.activityInMemory ? (
    <Box>
      <InMemory
        name={page.activityInMemory}
        birthDateString={page.dateOfBirthDeceased}
        deathDateString={page.dateOfDeathDeceased}
      />
    </Box>
  ) : null;
};

export default EditableInMemoryForm;
